import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Icons
import DownloadIcon from "assets/icons/icon-download.svg"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap; 
`

const StyledResources = styled.section`
background-color: ${colors.grayLight};
padding: 80px 10px;
    
	  ${breakpoint.small`
		flex-wrap: nowrap;
		padding: 120px 20px;
	  `}

  .block {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
 	column-gap: 40px;

    ${breakpoint.small`
      flex-wrap: nowrap;
    `}

    &--image {
      	width: 100%;
      	margin: 0 auto;
		display: flex;
		justify-content: center;

		${breakpoint.small`
			display: block;
			margin-top: 50px;
			width: 40%;
      		max-width: 550px;
		`}

		.imageResize {
			max-width: 450px;

			${breakpoint.small`
				max-width: 550px;
			`}
		}

	 }

    &--content {
		width: 100%;
	
		${breakpoint.small`
			width: 60%;
		`}

		h2 {
			margin-bottom: 40px;
			text-align: center;
			
			${breakpoint.small`
				text-align: left;
			`}
		}

		p {

			&:first-of-type {
			padding-bottom: 2rem;
			}
		}
	  
			.resources {
				padding-bottom: 2rem;
				margin-left: 2px;
				
				ul {
					max-width: 400px;

					${breakpoint.large`
						max-width: 500px;
					`}
				}
				
				li {
					line-height: 24px;
					margin-bottom: 10px;
	
					&:last-child {
						margin-bottom: 0;
					}
				
					a {
						color: ${colors.charcoal};
						font-size: 15px;
						display: flex;
						align-items: flex-start;
					
						&::before {
							content: url(${DownloadIcon});
							position: relative;
							top: 4px;
							margin-right: 12px;
							margin-top: -5px;
						}
					
						${breakpoint.medium`
						font-size: 17px;
							`}
				
				
						&:hover {
							color: ${colors.orange};
						}
					
					}
				}
			}
		}
  } 
`

const Resources = () => {
	return (
		<StyledResources id="downloads">
			<StyledContainer>
				<div className="block">
					<div className="block--content">
						<h2 data-aos="fade-up" data-aos-once="false">Downloads</h2>
						<p data-aos="fade-up" data-aos-once="false" data-aos-delay="100">
							Visby Medical developed PCR easy enough to be used by almost anyone, in any CLIA-waived setting. Below are resources to help run a successful test.
						</p>
						<ul className="resources" data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
							<li>
								<a href={ `/sexual-health-test/Visby-Medical-Sexual-Health-Point-of-Care-Quick-Reference-Guide.pdf` } target="_blank" rel="noopener noreferrer">Quick Reference Guide</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Point-of-Care-Instructions-for-Use.pdf`} target="_blank" rel="noopener noreferrer">Instructions for Use</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions-for-Use.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions for Use</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Specification-Sheet.pdf`} target="_blank" rel="noopener noreferrer">Specification Sheet</a>
							</li>
						</ul>
					</div>

					<div className="block--image" data-aos="fade-up" data-aos-once="false">
						<StaticImage
							src="../../../assets/images/sexual-health-test/device-sketch.svg"
							width={600}
							alt="Visby Medical Sexual Health Test"
							className="imageResize"
						/>
					</div>
				</div>
			</StyledContainer>
		</StyledResources>
	)
}

export default Resources
